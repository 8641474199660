import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Sitemark from "./SitemarkIcon";
import ColorModeIconDropdown from "../../shared-theme/ColorModeIconDropdown";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import Stack from "@mui/material/Stack";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SchoolIcon from "@mui/icons-material/School";
import TwitterIcon from "@mui/icons-material/X";
import FacebookIcon from "@mui/icons-material/Facebook";
import BlueSkyLogo from "./BlueSkyLogo";
import { Icon, SvgIcon } from "@mui/material";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexShrink: 0,
  borderRadius: `calc(${theme?.shape?.borderRadius}px + 8px)`,
  backdropFilter: "blur(24px)",
  border: "1px solid",
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: "8px 12px",
}));
export const linkWithRouter = React.forwardRef((props: any, ref) => (
  <Link {...props} component={RouterLink} ref={ref} />
));

export default function AppAppBar() {
  return (
    <AppBar
      position="fixed"
      enableColorOnDark
      sx={{
        boxShadow: 0,
        bgcolor: "transparent",
        backgroundImage: "none",
        mt: "calc(var(--template-frame-height, 0px) + 28px)",
      }}
    >
      <Container>
        <StyledToolbar variant="dense" disableGutters>
          <Box
            sx={{ flexGrow: 1, display: "flex", alignItems: "center", px: 0 }}
          >
            <Sitemark />
            <Box sx={{ display: "flex" }}>
              <Button
                variant="text"
                color="info"
                size="small"
                component={linkWithRouter}
                to="/"
              >
                Home
              </Button>
              <Button
                variant="text"
                color="info"
                size="small"
                component={linkWithRouter}
                to="/cv"
              >
                CV
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              gap: 1,
              alignItems: "center",
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              useFlexGap
              sx={{ justifyContent: "left", color: "text.secondary" }}
            >
              <IconButton
                color="inherit"
                size="small"
                href="https://scholar.google.com/citations?user=27bJMq8AAAAJ"
                aria-label="Google Scholar"
                sx={{ alignSelf: "center" }}
                title="Google Scholar"
              >
                <SchoolIcon />
              </IconButton>
              <IconButton
                color="inherit"
                size="small"
                href="https://www.linkedin.com/in/qing-tingting-liu-251bb6181/"
                aria-label="LinkedIn"
                sx={{ alignSelf: "center" }}
                title="LinkedIn"
              >
                <LinkedInIcon />
              </IconButton>
              <IconButton
                color="inherit"
                size="small"
                href="https://bsky.app/profile/bardjester.bsky.social"
                aria-label="BlueSky"
                sx={{ alignSelf: "center" }}
                title="BlueSky"
              >
                <SvgIcon>
                  <BlueSkyLogo />
                </SvgIcon>
              </IconButton>

              <IconButton
                color="inherit"
                size="small"
                href="https://www.facebook.com/profile.php?id=100005252685126"
                aria-label="X"
                sx={{ alignSelf: "center" }}
                title="X"
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                color="inherit"
                size="small"
                href="https://x.com/bard_jester"
                aria-label="X"
                sx={{ alignSelf: "center" }}
                title="X"
              >
                <TwitterIcon />
              </IconButton>
              <ColorModeIconDropdown />
            </Stack>
          </Box>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}
