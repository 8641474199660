import * as React from "react";
export default function Cv() {
  return (
    <iframe
      src="/QingLiuCV.pdf"
      width="100%"
      height="100%"
      style={{
        border: "none",
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100vh",
      }}
    ></iframe>
  );
}
