import * as React from "react";
import Box from "@mui/material/Box";

import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SchoolIcon from "@mui/icons-material/School";
import TwitterIcon from "@mui/icons-material/X";
import SitemarkIcon from "./SitemarkIcon";
import { Link as RouterLink } from "react-router-dom";
import BlueSkyLogo from "./BlueSkyLogo";
import { SvgIcon } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";

export default function Footer() {
  return (
    <React.Fragment>
      <Divider />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 4, sm: 8 },
          py: { xs: 8, sm: 10 },
          textAlign: { sm: "center", md: "left" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 4,
              minWidth: { xs: "100%", sm: "60%" },
            }}
          >
            <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
              <SitemarkIcon />
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Link
              color="text.secondary"
              variant="body2"
              component={RouterLink}
              to="/cv"
            >
              CV
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pt: { xs: 4, sm: 8 },
            width: "100%",
            borderTop: "1px solid",
            borderColor: "divider",
          }}
        >
          <div></div>
          <Stack
            direction="row"
            spacing={1}
            useFlexGap
            sx={{ justifyContent: "left", color: "text.secondary" }}
          >
            <IconButton
              color="inherit"
              size="small"
              href="https://scholar.google.com/citations?user=27bJMq8AAAAJ"
              aria-label="Google Scholar"
              sx={{ alignSelf: "center" }}
              title="Google Scholar"
            >
              <SchoolIcon />
            </IconButton>
            <IconButton
              color="inherit"
              size="small"
              href="https://www.linkedin.com/in/qing-tingting-liu-251bb6181/"
              aria-label="LinkedIn"
              sx={{ alignSelf: "center" }}
              title="LinkedIn"
            >
              <LinkedInIcon />
            </IconButton>
            <IconButton
              color="inherit"
              size="small"
              href="https://bsky.app/profile/bardjester.bsky.social"
              aria-label="BlueSky"
              sx={{ alignSelf: "center" }}
              title="BlueSky"
            >
              <SvgIcon>
                <BlueSkyLogo />
              </SvgIcon>
            </IconButton>

            <IconButton
              color="inherit"
              size="small"
              href="https://www.facebook.com/profile.php?id=100005252685126"
              aria-label="X"
              sx={{ alignSelf: "center" }}
              title="X"
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              color="inherit"
              size="small"
              href="https://x.com/bard_jester"
              aria-label="X"
              sx={{ alignSelf: "center" }}
              title="X"
            >
              <TwitterIcon />
            </IconButton>
          </Stack>
        </Box>
      </Container>
    </React.Fragment>
  );
}
