import * as React from "react";
import Container from "@mui/material/Container";
import Blog from "./blog/Blog";
import { Route, Routes } from "react-router-dom";
import Research from "./templates/Research";
import CV from "./templates/CV";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Blog />} />
      <Route path="/research" element={<Research />} />
      <Route path="/cv" element={<CV />} />
    </Routes>
  );
}
