import * as React from "react";

export default function SitemarkIcon() {
  return (
    <img
      src="/jester-192x192.png"
      alt="Sitemark"
      width={48}
      height={48}
      style={{ marginRight: 16 }}
    />
  );
}
