import * as React from "react";

export default function Research() {
  return (
    <div>
      <h1>
        <center>
          <p>This is About.</p>
          <a
            href="https://github.com/swarajgosavi/swarajgosavi"
            target="_blank"
            rel="noopener noreferrer"
          >
            About Me.
          </a>
        </center>
      </h1>
    </div>
  );
}
